import { CANVAS_DEV_FEATURES } from '@business/editor/devmode/_constants/canvasDevFeatures';
import {
  CreateOptionReturn,
  createOption,
} from '@business/editor/devmode/_services/_pures/createOption';

/**
 * 캔버스 개발모드는 토글 UI 만 존재합니다. (selects 미지원)
 * */
export const createCanvasOptions = (canvasDevFeatures: typeof CANVAS_DEV_FEATURES) => {
  const canvasDevFeatureDisplayNames = Object.keys(
    canvasDevFeatures
  ) as (keyof typeof canvasDevFeatures)[];

  return canvasDevFeatureDisplayNames.reduce((acc, cur) => {
    acc[cur] = createOption<boolean>({
      displayName: CANVAS_DEV_FEATURES[cur] ?? '',
      defaultValue: false,
      isCanvasDevOption: true,
    });
    return acc;
  }, {} as Record<keyof typeof canvasDevFeatures, CreateOptionReturn<boolean>>);
};

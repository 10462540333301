import { useCurrentUser } from '@business/user/_services/_hooks/useCurrentUser';
import { useDynamicAtomValue } from '@configs/jotai/useDynamicAtom';
import { DevKey, DevStore, devStoreAtom } from '../../devModeStore';

type UseDevModeReturn<T extends DevKey> = {
  value: DevStore[T]['value'];
  isAdmin: boolean;
};

/**
 * @returns {UseDevModeReturn} - { value, isAdmin }
 * value - 개발모드의 설정값
 * isAdmin - 유저가 개발모드 설정 권한이 있는 admin 인지 여부
 */
export const useDevMode = <T extends DevKey>(key: T): UseDevModeReturn<T> => {
  const { isAdmin } = useCurrentUser();
  const { value } = useDynamicAtomValue<DevStore, DevStore[T]>(devStoreAtom, (state) => state[key]);
  return {
    value,
    isAdmin: Boolean(isAdmin),
  };
};

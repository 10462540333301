import { PageSize } from '@business/design/_types/pageSize';
import { SizeLimit } from '@business/design/_types/sizeLimit';
import { SizeUnit } from '@business/design/_types/sizeUnit';

export const DEFAULT_UNIT: SizeUnit = 'PX';
export const DEFAULT_DPI = 96;
export const DEFAULT_PAGE_SIZE = 1080;

export const MIN_SHEET_SIZE = 32;
export const MAX_SHEET_SIZE = 8000;
export const DEV_MAX_SHEET_SIZE = 10000;

export const DEFAULT_PAGESIZE: PageSize = {
  width: DEFAULT_PAGE_SIZE,
  height: DEFAULT_PAGE_SIZE,
  unit: DEFAULT_UNIT,
  dpi: DEFAULT_DPI,
};

export const CM_PER_INCH = 2.54;

export const SIZE_LIMIT_BY_UNIT_MAP: SizeLimit = {
  PX: { min: 32, max: 8000 },
  MM: { min: 8, max: 2117 },
  CM: { min: 0.8, max: 211.7 },
  IN: { min: 0.33, max: 83.33 },
};

import { CSSProperties, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

export const MainPageSection = ({
  children,
  style,
}: PropsWithChildren<{ style?: CSSProperties }>) => {
  return <Section style={style}>{children}</Section>;
};

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  content-visibility: auto;
  width: 100%;

  box-sizing: border-box;
  padding: 80px 40px;

  ${({ theme }) =>
    theme.media.mobile(css`
      padding: 80px 32px;
    `)};
  ${({ theme }) =>
    theme.media.under500Mobile(css`
      padding: 80px 24px;
    `)};
`;

import { useTranslation } from '@configs/i18next';
import { Typography } from '@miri-unicorn/miricanvas-ds/v3';
import styled, { css } from 'styled-components';

export const MainDesignFeatureTitle = () => {
  const { translate } = useTranslation();

  return (
    <DesignFeatureTitleContainer>
      <Typography asChild>
        <SubTitle>{translate('HOMEPAGE.HOMEPAGE_223')}</SubTitle>
      </Typography>
      <Typography asChild>
        <MainTitle>{translate('HOMEPAGE.HOMEPAGE_224')}</MainTitle>
      </Typography>
    </DesignFeatureTitleContainer>
  );
};

const DesignFeatureTitleContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 12px;
`;

const SubTitle = styled.p`
  font: ${({ theme }) => theme.token.s_typography_title_2XL};
  color: ${({ theme }) => theme.token.s_color_brand_primary_default};

  ${({ theme }) =>
    theme.media.over500Mobile(
      css`
        font: ${({ theme }) => theme.token.s_typography_title_XL};
      `
    )}

  ${({ theme }) =>
    theme.media.under500Mobile(
      css`
        font: ${({ theme }) => theme.token.s_typography_title_M};
      `
    )}
`;

const MainTitle = styled.h2`
  font: ${({ theme }) => theme.token.s_typography_display_S};
  color: ${({ theme }) => theme.token.s_color_contents_default};

  ${({ theme }) =>
    theme.media.over500Mobile(
      css`
        font: ${({ theme }) => theme.token.s_typography_display_XS};
      `
    )}

  ${({ theme }) =>
    theme.media.under500Mobile(
      css`
        font: ${({ theme }) => theme.token.s_typography_title_XL};
      `
    )}
`;

import { DEFAULT_DPI, MAX_SHEET_SIZE } from '@business/design/_constants/pageSize';
import { Dimension } from '@business/design/_types/dimension';
import { PageSize } from '@business/design/_types/pageSize';
import { applyMathRound } from './dimensionUtil';
import { getPixel } from './sizeUnitUtil';

/**
 * @see 1.0 PageSize.ts 내 getSize()
 * @deprecated TODO: 2.0 런칭을 위해 일단 필요한 부분만 간략하게 가져온 버젼임. 추후 제대로 해당 로직 2.0 코드로 이관하는 게 좋아보임.
 */
export const getSize = (pageSize: PageSize): Dimension => {
  return {
    width: pageSize.width,
    height: pageSize.height,
  };
};

/**
 * @see 1.0 PageSize.ts 내 getPixelSize()
 * @deprecated TODO: 2.0 런칭을 위해 일단 필요한 부분만 간략하게 가져온 버젼임. 추후 제대로 해당 로직 2.0 코드로 이관하는 게 좋아보임.
 */
export const getPixelSize = (pageSize: PageSize): Dimension => {
  // 생성자에 dpi기본값을 줬어도 PageSize가 다른 방식으로 만들어지면 값이 비어있을 수 있다.
  if (!pageSize.dpi) {
    pageSize.dpi = DEFAULT_DPI;
  }

  const dimension = {
    width: getPixel(pageSize.width, pageSize.dpi, pageSize.unit),
    height: getPixel(pageSize.height, pageSize.dpi, pageSize.unit),
  };

  return applyMathRound(dimension);
};

/**
 * @see 1.0 PageSize.ts 내 getPixelLimitMax()
 * @deprecated TODO: 2.0 런칭을 위해 일단 필요한 부분만 간략하게 가져온 버젼임. 추후 제대로 해당 로직 2.0 코드로 이관하는 게 좋아보임.
 */
export const getPixelLimitMax = (pageSize: PageSize, maxSize = MAX_SHEET_SIZE): Dimension => {
  const pixelSize = getPixelSize(pageSize);

  // 픽셀단위 아닐때 max size 체크
  if (pageSize.unit !== 'PX' && Math.max(pixelSize.width, pixelSize.height) > maxSize) {
    const ratio = pixelSize.width / pixelSize.height;

    if (ratio > 1) {
      return {
        width: maxSize,
        height: Math.round(pixelSize.width / ratio),
      };
    } else {
      return {
        width: Math.round(pixelSize.height * ratio),
        height: maxSize,
      };
    }
  }

  return pixelSize;
};

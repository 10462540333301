import styled, { css } from 'styled-components';
import { Button as _Button, Typography } from '@miri-unicorn/miricanvas-ds/v3';
import { useTranslation } from '@configs/i18next';
import { useNewUserTargetIPs } from '@business/user/_services/_hooks/useNewUserTargetIPs';
import { useCurrentUser } from '@business/user/_services/_hooks/useCurrentUser';
import { useLoginModal } from '@ui/common/signin_modal/useLoginModal';
import { HEADER_HREF_MAP } from '@ui/main_page/_common/_layouts/header/_constants/HeaderHrefMap';
import { useMediaQuery } from 'react-responsive';

export const StartDesignCTA = () => {
  const { translate } = useTranslation();
  const isTargetIp = useNewUserTargetIPs();
  const { isLogin } = useCurrentUser();
  const { openSignInModal } = useLoginModal();
  const isUnder500mobile = useMediaQuery({ maxWidth: '499px' });
  const onClickStartDesign = () => {
    if (!isTargetIp) {
      window.location.href = HEADER_HREF_MAP.DESIGN;
    } else if (isLogin) {
      window.location.href = HEADER_HREF_MAP.DESIGN;
    } else {
      openSignInModal();
    }
  };

  return (
    <MainStartDesignContainer>
      <Typography asChild>
        <Title>{translate(isTargetIp ? 'HOMEPAGE.HOMEPAGE_234' : 'HOMEPAGE.HOMEPAGE_233')}</Title>
      </Typography>
      <Button onClick={onClickStartDesign} size={isUnder500mobile ? 'L' : 'XL'}>
        <Typography>
          {translate(
            isTargetIp
              ? isLogin
                ? 'HOMEPAGE.MAINPAGE_SECTION.MAIN_BUTTON'
                : 'HOMEPAGE.PRICING.CARD.FREE.ACTION.START_FREE'
              : 'HOMEPAGE.MAINPAGE_SECTION.MAIN_BUTTON'
          )}
        </Typography>
      </Button>
    </MainStartDesignContainer>
  );
};

const MainStartDesignContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 40px;

  background-color: ${({ theme }) => theme.token.s_color_brand_primary_default};
  border-radius: ${({ theme }) => theme.token.b_size_static_80};

  padding: 72px 48px;

  ${({ theme }) =>
    theme.media.under500Mobile(
      css`
        padding: 48px 24px;
      `
    )}
`;

const Title = styled.h2`
  font: ${({ theme }) => theme.token.s_typography_display_S};
  color: ${({ theme }) => theme.token.s_color_contents_inverse_default};
  text-align: center;

  ${({ theme }) =>
    theme.media.over500Mobile(
      css`
        font: ${({ theme }) => theme.token.s_typography_display_XS};
      `
    )}

  ${({ theme }) =>
    theme.media.under500Mobile(
      css`
        font: ${({ theme }) => theme.token.s_typography_title_XL};
      `
    )}
`;

const Button = styled(_Button).attrs({
  shape: 'square',
  color: 'white',
  variant: 'filled',
})``;

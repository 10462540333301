export type CreateOptionProps<R> = {
  displayName: string;
  defaultValue: R;
  selects?: R[];
  isCanvasDevOption?: boolean;
};

export type CreateOptionReturn<R> = {
  displayName: string;
  value: R;
  selects?: R[];
  isCanvasDevOption?: boolean;
};

/**
 * @example
 * // 이 경우에는 토글 UI 가 됩니다.
 * createOption<boolean>({ displayName: '테스트 1', defaultValue: false })
 * // 이 경우에는 selects 에 있는 값들이 드롭다운되는 UI 가 됩니다.
 * createOption<'apple' | 'banana' | 'orange'>({
 *     displayName: '테스트 2',
 *     defaultValue: 'apple',
 *     selects: ['apple', 'banana', 'orange'],
 *   })
 */
export const createOption = <R>({
  displayName,
  defaultValue,
  selects,
  isCanvasDevOption,
}: CreateOptionProps<R>): CreateOptionReturn<R> => {
  return {
    displayName,
    value: defaultValue,
    selects,
    isCanvasDevOption,
  };
};

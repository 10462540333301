import { LOCAL_STORAGE_KEY } from '@business/_constants/localStorageKey';
import { CANVAS_DEV_FEATURES } from '@business/editor/devmode/_constants/canvasDevFeatures';
import { createCanvasOptions } from '@business/editor/devmode/_services/_pures/createCanvasOptions';
import { createOption } from '@business/editor/devmode/_services/_pures/createOption';
import { shallowEqual } from '@miri-unicorn/miricanvas-utils/functions';
import { atom } from 'jotai';

/**
 * 개발모드 옵션을 추가하시려면,
 * 아래 devStore 에 추가해주시면 됩니다.
 *
 * 실제 View 나 훅에서 사용하실때는 useDevMode 훅스를 사용해주세요.
 * {@link useDevMode}
 *
 * 만약 캔버스 기능관련 개발모드를 추가해야한다면, {@link CANVAS_DEV_FEATURES} 를 참고해주세요.
 */
export const defaultDevStore = {
  devTools: createOption<boolean>({ displayName: '개발툴', defaultValue: false }),
  MOBILE_V_CONSOLE: createOption<boolean>({
    displayName: '모바일 vConsole',
    defaultValue: false,
  }),
  MOBILE_TEST: createOption<boolean>({
    displayName: '모바일 테스트/인앱결제 (앱에서만 작동)',
    defaultValue: false,
  }),
  ADVANCED_QR: createOption<boolean>({ displayName: 'QR 코드 고급 기능', defaultValue: false }),
  AI_PRESENTATION_POC_TEST: createOption<boolean>({
    displayName: 'AI 프레젠테이션 전체 생성 테스트',
    defaultValue: false,
  }),
  DESIGN_RESOURCE_LIST_WITH_SEARCH_SCORE: createOption<boolean>({
    displayName: '검탐색 스코어 확인 (스테이징 전용)',
    defaultValue: false,
  }),
  MAIN_PAGE_RENEWAL: createOption<boolean>({
    displayName: '메인페이지 리뉴얼',
    defaultValue: false,
  }),
  AI_LAYOUT: createOption<boolean>({
    displayName: 'AI 레이아웃',
    defaultValue: false,
  }),
  BASIC_LAYOUT: createOption<boolean>({
    displayName: 'AI 기본 레이아웃',
    defaultValue: false,
  }),
  ...createCanvasOptions(CANVAS_DEV_FEATURES),
};

export type DevKey = keyof typeof defaultDevStore;
export type DevStore = typeof defaultDevStore;

/**
 * localStorage 에 이미 저장된 devMode 옵션이 존재한다면 그 값을 가져오고, 존재하지 않는다면 기본값을 사용합니다.
 */
const getInitialDevStore = (): DevStore => {
  const localStorageDevStore =
    typeof localStorage !== 'undefined'
      ? localStorage.getItem(LOCAL_STORAGE_KEY.DEV_MODE_LOCAL_STORAGE_KEY)
      : null;

  return localStorageDevStore &&
    shallowEqual(
      Object.keys(JSON.parse(localStorageDevStore) as DevStore),
      Object.keys(defaultDevStore)
    )
    ? (JSON.parse(localStorageDevStore) as DevStore)
    : defaultDevStore;
};

export const devStoreAtom = atom(getInitialDevStore());

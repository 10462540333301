import fastDeepEquals from 'fast-deep-equal';
import { Atom, useAtom, useAtomValue } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { useIMemo } from '@utils/hooks/useIMemo';

export type DynamicAtomSelector<T, R = unknown> = (state: T) => R;

// 사용처에서 동적으로 atom 에서 원하는 값을 가져올 수 있도록 도와주는 hook.
export const useDynamicAtom = <T, R = unknown>(
  atom: Atom<T>,
  selector: DynamicAtomSelector<T, R>,
  deepEquals?: boolean
) => {
  const dynamicAtom = useIMemo(
    () => selectAtom(atom, selector, deepEquals ? fastDeepEquals : undefined),
    [deepEquals]
  );
  return useAtom(dynamicAtom);
};

export const useDynamicAtomValue = <T, R = unknown>(
  atom: Atom<T>,
  selector: DynamicAtomSelector<T, R>,
  deepEquals?: boolean
) => {
  const dynamicAtom = useIMemo(
    () => selectAtom(atom, selector, deepEquals ? fastDeepEquals : undefined),
    [deepEquals]
  );
  return useAtomValue(dynamicAtom);
};

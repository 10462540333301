import { match } from 'ts-pattern';
import dynamic from 'next/dynamic';
import { useCurrentLanguage } from '@configs/i18next/useCurrentLanguage';
import { MainPageLayoutProps } from '@ui/main_page/_common/_layouts/MainPageLayout.hook';

const KoreanMainPage = dynamic(
  () => import('@ui/main_page/main/KoreanMainPage').then((module) => module.KoreanMainPage),
  { ssr: true }
);
const GlobalMainPage = dynamic(
  () => import('@ui/main_page/main/GlobalMainPage').then((module) => module.GlobalMainPage),
  { ssr: true }
);
const JapanMainPage = dynamic(
  () => import('@ui/main_page/main/JapanMainPage').then((module) => module.JapanMainPage),
  { ssr: true }
);

export const MainPageSwitch = ({ initIsEventBannerOpen, bannerContents }: MainPageLayoutProps) => {
  const { currentLanguage } = useCurrentLanguage();
  return match(currentLanguage)
    .with('ko', () => (
      <KoreanMainPage
        initIsEventBannerOpen={initIsEventBannerOpen}
        bannerContents={bannerContents}
      />
    ))
    .with('ja', () => (
      <JapanMainPage
        initIsEventBannerOpen={initIsEventBannerOpen}
        bannerContents={bannerContents}
      />
    ))
    .otherwise(() => (
      <GlobalMainPage
        initIsEventBannerOpen={initIsEventBannerOpen}
        bannerContents={bannerContents}
      />
    ));
};

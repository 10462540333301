import { TeamScope, TemplateType } from '@miri-unicorn/miricanvas-api-client';
import { DEFAULT_DPI, MAX_SHEET_SIZE } from '@business/design/_constants/pageSize';
import { getPixelLimitMax } from '@business/design/_services/_pures/pageSizeUtil';
import { RequestParamKey, RequestParamKeyEnum } from '@business/design/_types/requestParamKey';
import { isEmpty } from '@miri-unicorn/miricanvas-utils/functions';

export const getNewTemplatePageQueryString = ({
  templateType,
  templateId,
  teamIdx,
  categoryIdx,
  teamScope,
}: {
  templateType: TemplateType;
  templateId: number;
  teamIdx?: number;
  categoryIdx?: number;
  teamScope?: TeamScope;
}) => {
  const { width, height, id: templateTypeId, unit = 'PX', purpose }: TemplateType = templateType;
  const pageSize = {
    width,
    height,
    unit,
    dpi: DEFAULT_DPI,
  };

  const size = getPixelLimitMax(pageSize, MAX_SHEET_SIZE);

  const param = new Map<RequestParamKey, string>();

  const setParam = (key: RequestParamKey, value: string): void => {
    if (!isEmpty(value)) {
      const newValue = encodeURIComponent(btoa(value));
      param.set(key, newValue);
    }
  };

  setParam(RequestParamKeyEnum.SHEET_W, String(size.width));
  setParam(RequestParamKeyEnum.SHEET_H, String(size.height));
  setParam(RequestParamKeyEnum.TEMPLATE_TYPE_ID, templateTypeId.toString());
  setParam(RequestParamKeyEnum.TEMPLATE_IDX, templateId.toString());
  setParam(RequestParamKeyEnum.TEMPLATE_PURPOSE, purpose);

  if (teamIdx) {
    setParam(RequestParamKeyEnum.TEAM_IDX, teamIdx.toString());
  }
  if (categoryIdx) {
    setParam(RequestParamKeyEnum.CATEGORY_IDX, categoryIdx.toString());
  }
  if (teamScope) {
    setParam(RequestParamKeyEnum.TEAM_SCOPE, teamScope.toString());
  }

  if (unit && unit !== 'PX') {
    setParam(RequestParamKeyEnum.SIZE_UNIT, unit);
    setParam(RequestParamKeyEnum.DPI, String(DEFAULT_DPI));
  }

  const params: Array<string> = [];
  param.forEach((value, key) => params.push(`${key}=${value}`));

  const paramString = params.join('&');
  return paramString;
};

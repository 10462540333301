import { Dimension } from '@business/design/_types/dimension';

/**
 * @see 1.0 Dimension.ts 내 applyMathRound()
 * @deprecated TODO: 2.0 런칭을 위해 일단 필요한 부분만 간략하게 가져온 버젼임. 추후 제대로 해당 로직 2.0 코드로 이관하는 게 좋아보임.
 */
export const applyMathRound = (dimension: Dimension): Dimension => {
  return {
    width: Math.round(dimension.width),
    height: Math.round(dimension.height),
  };
};

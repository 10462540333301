import { SizeUnit } from '@miri-unicorn/miricanvas-editor/lib/types/miricanvas-shared-web/src/common/model/SizeUnit';

/**
 * @see 1.0 SizeUnit.ts 내 referenceUnitValue()
 * @deprecated TODO: 2.0 런칭을 위해 일단 필요한 부분만 간략하게 가져온 버젼임. 추후 제대로 해당 로직 2.0 코드로 이관하는 게 좋아보임.
 */
export const referenceUnitValue = (unit: SizeUnit, dpi?: number): number => {
  switch (unit) {
    case 'MM':
      return 25.4;
    case 'CM':
      return 2.54;
    case 'IN':
      return 1;
    case 'PX':
      if (dpi) {
        return dpi;
      } else {
        throw new Error('픽셀 변환은 dpi값이 필요합니다');
      }
    default:
      throw new Error('잘못된 단위입니다');
  }
};

/**
 * @see 1.0 SizeUnit.ts 내 changeUnit()
 * @deprecated TODO: 2.0 런칭을 위해 일단 필요한 부분만 간략하게 가져온 버젼임. 추후 제대로 해당 로직 2.0 코드로 이관하는 게 좋아보임.
 */
export const changeUnit = (
  size: number,
  originUnit: SizeUnit,
  changedUnit: SizeUnit,
  dpi?: number
) => {
  if (originUnit === changedUnit) {
    return size;
  }

  if (!dpi && (originUnit === 'PX' || changedUnit === 'PX')) {
    throw new Error('픽셀 변환은 dpi값이 필요합니다');
  }

  const originMU = referenceUnitValue(originUnit, dpi);
  const changedMU = referenceUnitValue(changedUnit, dpi);

  return (size / originMU) * changedMU;
};

/**
 * @see 1.0 SizeUnit.ts 내 getPixel()
 * @deprecated TODO: 2.0 런칭을 위해 일단 필요한 부분만 간략하게 가져온 버젼임. 추후 제대로 해당 로직 2.0 코드로 이관하는 게 좋아보임.
 */
export const getPixel = (size: number, dpi: number, sizeUnit: SizeUnit): number => {
  return changeUnit(size, sizeUnit, 'PX', dpi);
};

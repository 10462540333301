import { useContext } from 'react';
import { PaymentPopupContext } from './PaymentPopupProvider';
import { PopupContext } from './PopupProvider';

/**
 * 팝업을 조금 더 간단하게 렌더링할 수 있는 hook 을 제공합니다.
 * 
 * ***PopupProvider 내부*** 에서 사용 가능합니다.
 * @example 
 * ```tsx
   const { openPopup, closePopup } = usePopup();
   openPopup({
      component: PopupView,
      props: {  // props: ComponentProps<PopupView>
       ...PopupViewProps, 
        onClose: closePopup,
      },
    });
 * 
 * ```
 */
export const usePopup = () => {
  const contextValue = useContext(PopupContext);
  if (contextValue === null) throw new Error('PopupProvider 내부에서 호출해주세요!');

  return contextValue;
};

/**
 * TODO: 추후 usePopup이 리팩토링 되면 삭제될 예정
 */
export const usePaymentPopup = () => {
  const contextValue = useContext(PaymentPopupContext);
  if (contextValue === null) throw new Error('PaymentPopupProvider 내부에서 호출해주세요!');

  return contextValue;
};

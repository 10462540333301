import { GetStaticPropsContext } from 'next';
import { StaticPropsForDesign } from '@routing/ssr/_services/getStaticPropsForDesign';
import { initServerSideDefault } from '@routing/ssr/_services/initServerSideDefault';
import { StaticPageParams } from '@routing/ssr/_types/staticPageParams';
import { DEFAULT_LANGUAGE, isServiceableLanguageType } from '@configs/i18next/language_util';
import { useRouter } from '@configs/next_ssr';
import { QueryClient } from '@configs/react-query';
import { SeoMetaTags } from '@configs/seo/meta_tag/SeoMetaTag';
import { getMainSeo } from '@configs/seo/utils/getSeoConfig';
import { MainEventBannerType } from '@ui/event_banner/_types/EventBannerType';
import {
  getBannerContents,
  getBannerData,
  isEventBannerOpen,
} from '@ui/event_banner/_utils/eventBannerSsrUtil';
import { MainPageSwitch } from '@ui/main_page/main/MainPageSwitch';
import { useDevMode } from '@business/editor/devmode/_services/_hooks/useDevMode';
import { MainPageProvider } from '@ui/main_page/_common/_layouts/MainPageProvider';
import { MainPage } from '@ui/main_page/main/MainPage';

export const getStaticProps = async (context: GetStaticPropsContext<StaticPageParams>) => {
  const initialLanguage = isServiceableLanguageType(context.params?.lang)
    ? context.params?.lang
    : DEFAULT_LANGUAGE;
  const { i18nConfig, serverSideAxiosInstance } = await initServerSideDefault({ initialLanguage });
  /**
   * 띠배너 띄우기 여부
   */
  const queryClient = new QueryClient();
  const webEnvironmentData = await getBannerData(queryClient, serverSideAxiosInstance);
  const initIsEventBannerOpen = isEventBannerOpen(webEnvironmentData) || false;
  const bannerContents = getBannerContents(webEnvironmentData) || [];
  return {
    props: {
      ...i18nConfig,
      language: initialLanguage,
      initIsEventBannerOpen: initIsEventBannerOpen,
      bannerContents: bannerContents,
    },
    revalidate: 3600,
  };
};

const Page = (
  props: StaticPropsForDesign & {
    initIsEventBannerOpen: boolean;
    bannerContents: MainEventBannerType[];
  }
) => {
  const router = useRouter();
  const { value: isDevMode } = useDevMode('MAIN_PAGE_RENEWAL');

  if (router.isFallback) {
    return <></>;
  }

  return (
    <>
      <SeoMetaTags seoConfig={getMainSeo(props.language)} />
      <MainPageProvider>
        {isDevMode ? (
          <MainPage
            initIsEventBannerOpen={props.initIsEventBannerOpen}
            bannerContents={props.bannerContents}
          />
        ) : (
          <MainPageSwitch
            initIsEventBannerOpen={props.initIsEventBannerOpen}
            bannerContents={props.bannerContents}
          />
        )}
      </MainPageProvider>
    </>
  );
};

export const getStaticPaths = async () => {
  return {
    paths: [{ params: { lang: 'ko' } }],
    fallback: 'blocking',
  };
};

export default Page;

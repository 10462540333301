import { useTranslation } from '@configs/i18next';
import { Typography } from '@miri-unicorn/miricanvas-ds/v3';
import { MOBILE_BREAK_MAX, X_SMALL_MOBILE_BREAK_MAX } from '@styles/media/main_page_media';
import { MainPageSectionTransitionProps } from '@ui/main_page/_constants/TransitionProps';
import { InViewTransition } from '@utils/components/InViewTransition';
import { useViewportWidth } from '@utils/hooks/viewport/useViewportWidth';
import styled, { css } from 'styled-components';
import { MainActiveUserRowItem } from './MainActiveUserRowItem';

export const MainActiveUser = () => {
  const { translate } = useTranslation();
  const isUnder768px = useViewportWidth((width) => width <= MOBILE_BREAK_MAX);
  const isUnder500px = useViewportWidth((width) => width <= X_SMALL_MOBILE_BREAK_MAX);

  return (
    <Container>
      <InViewTransition {...MainPageSectionTransitionProps}>
        <Header>
          <Typography
            color={(token) => token.s_color_brand_primary_default}
            variant='title'
            size={isUnder500px ? 'M' : isUnder768px ? 'XL' : '2XL'}>
            {translate('HOMEPAGE.HOMEPAGE_203')}
          </Typography>
          <Typography
            asChild
            color={(token) => token.s_color_contents_default}
            variant={isUnder500px ? 'title' : 'display'}
            size={isUnder500px ? 'XL' : isUnder768px ? 'XS' : 'S'}>
            <h2>{translate('HOMEPAGE.HOMEPAGE_204')}</h2>
          </Typography>
        </Header>
      </InViewTransition>
      <InViewTransition
        {...MainPageSectionTransitionProps}
        style={{ contentVisibility: 'initial' }}>
        <Body>
          <MainActiveUserRowItem
            iconName='people_team'
            title={translate('HOMEPAGE.HOMEPAGE_205')}
            subTitle={translate('HOMEPAGE.HOMEPAGE_206')}
            isUnder500px={Boolean(isUnder500px)}
          />
          <MainActiveUserRowItem
            iconName='url'
            title={translate('HOMEPAGE.HOMEPAGE_207')}
            subTitle={translate('HOMEPAGE.HOMEPAGE_208')}
            isUnder500px={Boolean(isUnder500px)}
          />
          <MainActiveUserRowItem
            iconName='brush'
            title={translate('HOMEPAGE.HOMEPAGE_209')}
            subTitle={translate('HOMEPAGE.HOMEPAGE_210')}
            isUnder500px={Boolean(isUnder500px)}
          />
        </Body>
      </InViewTransition>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  gap: 48px;

  ${({ theme }) =>
    theme.media.mobile(css`
      gap: 40px;
    `)}
`;

const Header = styled.div`
  display: grid;
  gap: 12px;
  justify-items: center;
`;

const Body = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 400px));
  justify-items: center;
`;

import { DependencyList, useMemo } from 'react';

/**
 * 의존성 배열과 관련된 eslint 경고를 무시하고, useMemo 를 사용하고 싶을때 사용하는 훅스.
 * 이 훅스를 사용하면, 명시적으로 의존성 배열과 관련된 eslint 룰을 무시하고 있는 것임.
 * I 는 ignore 를 의미함.
 */
export const useIMemo = <T>(factory: () => T, deps: DependencyList) => {
  // eslint-disable-next-line
  return useMemo(factory, deps);
};

import { useCurrentLanguage } from '@configs/i18next/useCurrentLanguage';
import { MIRICANVAS_RESOURCE_DOMAIN } from '@constants/ResourceDomain';
import { Typography } from '@miri-unicorn/miricanvas-ds/v3';
import styled, { css } from 'styled-components';

type MainDesignFeatureItemProps = {
  subtitle: string;
  title: string;
  description: string;
  button?: React.ReactNode;
  imageURL: string;
};

export const MainDesignFeatureItem = ({
  subtitle,
  title,
  description,
  button,
  imageURL,
}: MainDesignFeatureItemProps) => {
  const { isKorean, isJapanese } = useCurrentLanguage();

  return (
    <DesignFeatureContent>
      <ImageContainer
        src={`${MIRICANVAS_RESOURCE_DOMAIN}2_0/image/main/landing/${imageURL}${
          isKorean ? '_kr' : isJapanese ? '_jp' : '_en'
        }.webp`}
      />
      <TextContainer>
        <TitleContainer>
          <Typography asChild>
            <SubTitle>{subtitle}</SubTitle>
          </Typography>
          <Typography asChild>
            <Title>{title}</Title>
          </Typography>
        </TitleContainer>
        <Typography asChild>
          <Description>{description}</Description>
        </Typography>
        {button}
      </TextContainer>
    </DesignFeatureContent>
  );
};

const DesignFeatureContent = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.token.s_color_border_default};
  border-radius: ${({ theme }) => theme.token.b_size_static_80};
  background-color: ${({ theme }) => theme.token.s_color_background_default};

  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;

  padding: 24px;

  ${({ theme }) =>
    theme.media.under1280Desktop(
      css`
        flex-direction: column;
        gap: 24px;
      `
    )}

  ${({ theme }) =>
    theme.media.over500Mobile(
      css`
        gap: 16px;
        padding: 16px;
      `
    )}
`;

const ImageContainer = styled.img`
  max-width: 560px;

  border-radius: ${({ theme }) => theme.token.b_size_static_70};

  ${({ theme }) =>
    theme.media.under1280Desktop(
      css`
        max-width: 100%;
        width: 100%;
      `
    )}
`;

const TextContainer = styled.div`
  max-width: 560px;
  display: flex;
  flex-direction: column;
  text-align: left;

  padding: 24px 0;
  gap: 32px;

  ${({ theme }) =>
    theme.media.under1280Desktop(
      css`
        max-width: 100%;
        width: 100%;
        padding: 0;
        gap: 24px;
      `
    )}

  ${({ theme }) =>
    theme.media.over500Mobile(
      css`
        gap: 12px;
      `
    )}

  ${({ theme }) =>
    theme.media.under500Mobile(
      css`
        gap: 8px;
      `
    )}
`;

const SubTitle = styled.p`
  color: ${({ theme }) => theme.token.s_color_surface_medium};
  font: ${({ theme }) => theme.token.s_typography_title_XL};

  ${({ theme }) =>
    theme.media.over500Mobile(
      css`
        font: ${({ theme }) => theme.token.s_typography_title_M};
      `
    )}

  ${({ theme }) =>
    theme.media.under500Mobile(
      css`
        font: ${({ theme }) => theme.token.s_typography_title_S};
      `
    )}
`;

const Title = styled.h3`
  font: ${({ theme }) => theme.token.s_typography_display_XS};
  color: ${({ theme }) => theme.token.s_color_contents_default};

  ${({ theme }) =>
    theme.media.over500Mobile(
      css`
        font: ${({ theme }) => theme.token.s_typography_title_XL};
      `
    )}

  ${({ theme }) =>
    theme.media.under500Mobile(
      css`
        font: ${({ theme }) => theme.token.s_typography_title_L};
      `
    )}
`;

const Description = styled.p`
  font: ${({ theme }) => theme.token.s_typography_body_XL};
  color: ${({ theme }) => theme.token.s_color_contents_sub};

  ${({ theme }) =>
    theme.media.under500Mobile(
      css`
        font: ${({ theme }) => theme.token.s_typography_body_M};
      `
    )}
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${({ theme }) =>
    theme.media.over500Mobile(
      css`
        gap: 8px;
      `
    )}

  ${({ theme }) =>
    theme.media.under500Mobile(
      css`
        gap: 4px;
      `
    )}
`;

import { getNewTemplatePageQueryString } from '@business/design/_services/_pures/templatePageUtil';
import { RequestParamKey } from '@business/design/_types/requestParamKey';
import { isWebView } from '@configs/devices/device_util';
import { V2_BASE_PATH } from '@configs/environments/base_url';
import { TemplateType } from '@miri-unicorn/miricanvas-api-client';
import { getRandomString, padStart } from '@miri-unicorn/miricanvas-utils/functions';

/**
 * @see 1.0 UnicornPathUtil.ts 내 decodeIdx()
 * 에디터 화면 URL 의 designKey 를 designId로 변환하는 함수
 * 가능하면 useDesignKey hook 사용하기!
 */
export const decodeIdx = (designKey: string): number => {
  return parseInt(designKey.substring(1), 36);
};

/**
 * @see 1.0 UnicornPathUtil.ts 내 encodeIdx()
 * designId 를 URL 에 사용되는 designKey 로 변환하는 함수
 * @param designId
 * @returns
 */
export const encodeIdx = (designId: number): string => {
  return `1${designId.toString(36)}`; // '1' + (36진수로 변환)
};

/**
 * @see 1.0 UnicornPathUtil.ts 내 getCopyRequestId()
 * @param designIdx
 * @returns
 */
export const getCopyRequestId = (designIdx: number): string => {
  const rand = Math.floor(Math.random() * 1000000);
  const parity = (designIdx * 177 + rand * 1579) % 1000000;
  return `${padStart(rand.toString(), 6, '0')}${padStart(parity.toString(), 6, '0')}`;
};

/**
 * @see 1.0 UnicornPathUtil.ts 내 getCopyDesignUrl()
 * 디자인 복사 URL 반환
 * https://wiki.miridih.com/pages/viewpage.action?pageId=111805255
 * @param designIdx 디자인 인덱스
 * @param teamIdx
 * @param folderId
 */
export const getCopyDesignUrl = (designIdx: number, teamIdx: number, folderId: string) => {
  const copyRequestId = getCopyRequestId(designIdx);
  return `/copydesign/${encodeIdx(
    designIdx
  )}/key/${copyRequestId}?teamIdx=${teamIdx}&teamScope=INDIVIDUAL&folderIdx=${folderId}`;
};

/**
 * 현재 url에서 쿼리스트링 부분을 교체한 design 페이지 url을 리턴하는 함수
 * @param newQueryStringUrl "?" 를 제외한 쿼리 스트링 문자열
 * @returns
 */
export const getQueryStringReplacedDesignUrl = (newQueryStringUrl: string): string => {
  return `${V2_BASE_PATH}/${isWebView() ? 'webview/' : ''}design?${newQueryStringUrl}`;
};

export const getDesignUrl = (param: PartialRecord<RequestParamKey, string>) => {
  const b64encoded = Object.entries(param).reduce<Record<string, string>>((acc, [key, value]) => {
    if (value) {
      acc[key] = encodeURIComponent(btoa(value));
    }
    return acc;
  }, {});
  const queryString = new URLSearchParams(b64encoded).toString();
  return getQueryStringReplacedDesignUrl(queryString);
};

export const getTemplateDesignUrl = (templateId: number, templateType: TemplateType) => {
  const newQueryString = getNewTemplatePageQueryString({
    templateId,
    templateType,
  });
  return getQueryStringReplacedDesignUrl(newQueryString);
};

/**
 * 디자인 저장할 때 랜덤으로 생성하는 키
 */
export const getDesignSaveRandomKey = () => `lp${getRandomString(10)}`;

import { IconName } from '@miri-unicorn/miricanvas-ds';
import { Button, Typography } from '@miri-unicorn/miricanvas-ds/v3';
import styled from 'styled-components';

export const MainActiveUserRowItem = ({
  iconName,
  title,
  subTitle,
  isUnder500px,
}: {
  iconName: IconName;
  title: string;
  subTitle: string;
  isUnder500px: boolean;
}) => (
  <Container>
    <Button size={isUnder500px ? 'L' : 'XL'} color='black' variant='outlined'>
      <Button.Icon iconName={iconName} />
    </Button>
    <TextContainer>
      <Typography
        color={(token) => token.s_color_contents_default}
        variant='title'
        size={isUnder500px ? 'XL' : '2XL'}>
        {title}
      </Typography>
      <Typography
        color={(token) => token.s_color_contents_default}
        variant='body'
        size={isUnder500px ? 'XL' : '2XL'}>
        {subTitle}
      </Typography>
    </TextContainer>
  </Container>
);

const Container = styled.li`
  display: grid;
  justify-items: center;
  gap: 20px;
  grid-template-rows: auto 1fr;
`;

const TextContainer = styled.div`
  display: grid;
  gap: 4px;
  justify-items: center;
  text-align: center;
`;

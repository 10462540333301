import { MainPageSectionTransitionProps } from '@ui/main_page/_constants/TransitionProps';
import { InViewTransition } from '@utils/components/InViewTransition';
import { useTranslation } from '@configs/i18next';
import styled, { css } from 'styled-components';
import { MainDesignFeatureItem } from './MainDesignFeatureItem';
import { MainDesignFeatureTitle } from './MainDesignFeatureTitle';
import { MediaQuery } from '@utils/components/MediaQuery';

import { openMiricanvasAppDownload } from '@ui/common/_utils/openMiricanvasAppDownload';
import { Button, Typography } from '@miri-unicorn/miricanvas-ds/v3';
import { StartDesignCTA } from './StartDesignCTA';
import { useCurrentLanguage } from '@configs/i18next/useCurrentLanguage';

export const MainDesignFeature = () => {
  const { translate } = useTranslation();
  const { isKorean, isJapanese } = useCurrentLanguage();
  const showDownloadAppButton = isKorean || isJapanese;
  return (
    <DesignFeatureContainer>
      <InViewTransition {...MainPageSectionTransitionProps}>
        <MainDesignFeatureTitle />
      </InViewTransition>

      <InViewTransition {...MainPageSectionTransitionProps}>
        <MainDesignFeatureItem
          subtitle={translate('HOMEPAGE.HOMEPAGE_225')}
          title={translate('HOMEPAGE.HOMEPAGE_226')}
          description={translate('HOMEPAGE.HOMEPAGE_227')}
          imageURL={'element'}
        />
      </InViewTransition>

      <InViewTransition {...MainPageSectionTransitionProps}>
        <MainDesignFeatureItem
          subtitle={translate('HOMEPAGE.HOMEPAGE_228')}
          title={translate('HOMEPAGE.HOMEPAGE_229')}
          description={translate('HOMEPAGE.HOMEPAGE_230')}
          imageURL={'animation'}
        />
      </InViewTransition>

      <InViewTransition {...MainPageSectionTransitionProps}>
        <MainDesignFeatureItem
          subtitle={translate('HOMEPAGE.HOMEPAGE_231')}
          title={translate('HOMEPAGE.MAINPAGE_VALUE_TITLE_2')}
          description={translate('HOMEPAGE.HOMEPAGE_232')}
          imageURL={'mobile'}
          button={
            showDownloadAppButton && (
              <MediaQuery from={500}>
                <ButtonContainer>
                  <Button
                    size='M'
                    variant='filled'
                    color='primary'
                    shape='square'
                    onClick={openMiricanvasAppDownload}>
                    <Typography>{translate('SHARED.SHARED_375')}</Typography>
                  </Button>
                </ButtonContainer>
              </MediaQuery>
            )
          }
        />
      </InViewTransition>

      <InViewTransition {...MainPageSectionTransitionProps}>
        <StartDesignCTA />
      </InViewTransition>
    </DesignFeatureContainer>
  );
};

const DesignFeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  gap: 48px;

  ${({ theme }) =>
    theme.media.under500Mobile(css`
      gap: 40px;
    `)}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

import { DevFeature } from '@miri-unicorn/miricanvas-editor';

/**
 * # 캔버스쪽 개발모드 추가 방법
 * 예를들어 hide 라는 옵션을 추가해서 이 값이 true 라면 캔버스를 아예 숨기는 옵션을 만든다고 해봅시다.
 * 먼저 캔버스 작업자가 DevFeature 에 hide 라는 타입을 추가하고,  COMMAND_SET_DEV_MODE 커맨드에 hide 가 왔을때 캔버스를 숨기는 로직을 추가합니다.
 * 그 뒤 웹 작업자는 {@link CANVAS_DEV_FEATURES} 에 hide: "캔버스 숨기기" 와 같이 옵션을 추가하면 됩니다. (여기까지만 하면 끝.)
 * 개발모드 UI 에서 방금 추가한 "캔버스 숨기기" 옵션이 보이게 되고, 토글 시 COMMAND_SET_DEV_MODE 커멘드의 payload 로 hide 가 전달됩니다.
 */
export const CANVAS_DEV_FEATURES: { [key in DevFeature]?: string } = {
  /**
   * 예시)
   * Hide: "캔버스 숨기기"
   */
  TEXT_3: '텍스트 3.0',
  MOBILE_USER_VIDEO_SMALL_RESOURCE: '업로드 동영상 저해상도',
  AI_RECOMMENDED_LAYOUT: 'AI 레이아웃 스타일 대치 후 추천',
};

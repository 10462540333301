import { InViewTransitionProps } from '@utils/components/InViewTransition';

export const MainPageSectionTransitionProps: Omit<InViewTransitionProps, 'children'> = {
  duration: 0.5,
  yOffset: 24,
  inViewMargin: '-24px',
  variant: {
    hidden: { y: 24, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  },
  style: { width: '100%' },
};

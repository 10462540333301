import { MainPageLayoutProps } from '@ui/main_page/_common/_layouts/MainPageLayout.hook';
import { MainPageLayout } from '@ui/main_page/_common/_layouts/MainPageLayout';
import { MainPageSection } from '@ui/main_page/main/body/section/MainPageSection';
import { MainTemplateCarousel } from '@ui/main_page/main/body/section/main_template_carousel/MainTemplateCarousel';
import { useCurrentLanguage } from '@configs/i18next/useCurrentLanguage';
import { InteractionSection } from '@ui/main_page/main/body/section/main_3d_interaction/InteractionSection';
import { MainHero } from '@ui/main_page/main/body/section/main_hero/MainHero';
import { MainActiveUser } from '@ui/main_page/main/body/section/main_active_user/MainActiveUser';
import { MainTemplateType } from '@ui/main_page/main/body/section/main_template_type/MainTemplateType';
import { MainAIFeature } from '@ui/main_page/main/body/section/main_ai_feature/MainAIFeature';
import { MainPhotoFeature } from '@ui/main_page/main/body/section/main_photo_feature/MainPhotoFeature';
import { MainDesignFeature } from '@ui/main_page/main/body/section/main_design_feature/MainDesignFeature';

export const MainPage = ({ initIsEventBannerOpen, bannerContents }: MainPageLayoutProps) => {
  const { currentLanguage } = useCurrentLanguage();
  return (
    <MainPageLayout initIsEventBannerOpen={initIsEventBannerOpen} bannerContents={bannerContents}>
      <MainPageSection>
        <MainHero />
      </MainPageSection>
      {['ja', 'ko'].includes(currentLanguage) ? <MainTemplateCarousel /> : <InteractionSection />}
      <MainPageSection>
        <MainActiveUser />
      </MainPageSection>
      <MainPageSection>
        <MainTemplateType />
      </MainPageSection>
      <MainPageSection>
        <MainAIFeature />
      </MainPageSection>
      <MainPageSection>
        <MainPhotoFeature />
      </MainPageSection>
      <MainPageSection>
        <MainDesignFeature />
      </MainPageSection>
    </MainPageLayout>
  );
};

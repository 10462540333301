import { useRef } from 'react';
import { AnimatePresence, motion, useInView, UseInViewOptions, Variants } from 'framer-motion';

type MarginType = UseInViewOptions['margin'];

export type InViewTransitionProps = {
  children: React.ReactNode;
  variant?: Variants;
  duration?: number;
  delay?: number;
  yOffset?: number;
  inViewMargin?: MarginType;
  blur?: string;
  style?: React.CSSProperties;
};

/**
 * DOM이 viewport에 들어왔을때 애니메이션 상호작용 제공.
 * @example
 *  ```tsx
 *       <InViewTransition>
 *         <Component />
 *       </InViewTransition>
 *  ```
 */
export const InViewTransition = ({
  children,
  variant,
  duration = 0.4,
  delay = 0,
  yOffset = 12,
  inViewMargin = '-50px',
  blur = '6px',
  style,
}: InViewTransitionProps) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: inViewMargin });
  const defaultVariants: Variants = {
    hidden: { y: yOffset, opacity: 0, filter: `blur(${blur})` },
    visible: { y: 0, opacity: 1, filter: `blur(0px)` },
  };
  const combinedVariants = variant || defaultVariants;
  return (
    <AnimatePresence>
      <motion.div
        style={{ contentVisibility: 'auto', ...style }}
        ref={ref}
        initial='hidden'
        animate={isInView ? 'visible' : 'hidden'}
        exit='hidden'
        variants={combinedVariants}
        transition={{
          delay: 0.04 + delay,
          duration,
          ease: 'easeOut',
        }}>
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

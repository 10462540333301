import { atom } from 'jotai';

type AppInfo = {
  // 설치된 스토어 정보, e.g. Play Store, AppStore, TestFlight 등
  installedStore: string;
  // 앱 버전, e.g. 1.0.0
  appVersion: string;
  // e.g. com.miridih.miricanvas
  appBundleId: string;
};
export const appInfoAtom = atom<AppInfo | undefined>(undefined);
